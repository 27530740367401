import { hasPermission, Permission } from '@asaprint/common/access.js';
import UnauthorizedError from '@asaprint/asap/components/UnauthorizedError.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import { Authenticated } from '@asaprint/asap/decorators/authenticated.js';
import { AUTH_LOGIN_ROUTE } from '@asaprint/asap/routes.js';
import { url } from '@engined/core/services/routes.js';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface AuthorizedConfig {
  loginUrl?: string;
  all?: boolean;
}

const defaultConfig: Partial<AuthorizedConfig> = {
  loginUrl: AUTH_LOGIN_ROUTE,
};

interface OwnProps {
  loginUrl: string;
  permission: Permission | Permission[];
  all?: boolean;
  children: React.ReactNode;
}

type Props = OwnProps;

let Authorized: React.FunctionComponent<Props> = ({ loginUrl, permission, all = false, children }) => {
  const { requestUser } = useAuth();
  const location = useLocation();

  const destination = url(
    loginUrl,
    {},
    {
      next: `${location.pathname}${location.search}`,
    },
  );

  // TODO: Display error message instead of redirect?
  return (
    <>
      {!requestUser ? (
        <Navigate to={destination} replace />
      ) : !hasPermission(requestUser.permissions as Permission[], permission, all) ? (
        <UnauthorizedError />
      ) : (
        children
      )}
    </>
  );
};

Authorized.displayName = 'Authorized';

Authorized = React.memo<Props>(Authorized);

export default function authorized<TProps>(
  permission: Permission | Permission[],
  config: AuthorizedConfig = {},
): (Component: React.ComponentType<TProps> | React.ExoticComponent<TProps>) => React.ComponentClass<TProps> {
  const options = {
    ...defaultConfig,
    ...config,
  };

  // TODO: Rewrite to function component?
  return (Component: React.ComponentType<TProps>): React.ComponentClass<TProps> => {
    class AuthorizedComponent extends React.Component<TProps> {
      public static displayName = `authorized(${Component.displayName || Component.name})`;

      public override render() {
        return (
          <Authenticated loginUrl={options.loginUrl}>
            <Authorized loginUrl={options.loginUrl} permission={permission} all={options.all}>
              <Component {...this.props} />
            </Authorized>
          </Authenticated>
        );
      }
    }

    hoistNonReactStatics(AuthorizedComponent, Component);

    return AuthorizedComponent;
  };
}
