import { DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import {
  NOT_AUTHORIZED_DESCRIPTION,
  NOT_AUTHORIZED_GOTO,
  NOT_AUTHORIZED_TITLE,
} from '@asaprint/asap/locales/client.js';
import Interpolate from '@engined/client/components/Interpolate.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { url } from '@engined/core/services/routes.js';
import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

interface Props {}

const UnauthorizedError: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const { language } = useLocale();

  return (
    <Stack alignItems="center" marginTop={8}>
      <Typography variant="h1" component="h1">
        403!
      </Typography>
      <Typography variant="h3" component="h3">
        <Interpolate resource={NOT_AUTHORIZED_TITLE} />
      </Typography>
      <Box textAlign="center" component="p">
        <Interpolate resource={NOT_AUTHORIZED_DESCRIPTION} />
        <br />
        <Interpolate
          resource={NOT_AUTHORIZED_GOTO}
          components={[
            <Link
              key="link"
              component={RouterLink}
              to={url(DASHBOARD_ROUTE, { language })}
              onClick={(event) => {
                event.preventDefault();
                navigate(-1);
              }}
            />,
          ]}
        />
      </Box>
    </Stack>
  );
};

UnauthorizedError.displayName = 'UnauthorizedError';

export default UnauthorizedError;
